var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workbench"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"cont card"},[_c('div',{staticClass:"title"},[_vm._v("快捷方式")]),_c('div',{staticClass:"kuaijieIcon"},[_c('a-tooltip',{attrs:{"placement":"bottom","trigger":"click","overlayClassName":"setting-workbench"}},[_c('template',{slot:"title"},_vm._l((_vm.sideBarRoutes),function(item,index){return _c('div',{key:index,staticClass:"single"},[(item.path !== '/home')?[_c('div',{staticClass:"menu-title"},[_vm._v(_vm._s(item.name))]),(!!item.children)?_c('div',{staticClass:"sub-list"},_vm._l((item.children),function(sub){return _c('div',{key:sub.path,staticClass:"sub-title"},[_c('a-checkbox',{attrs:{"defaultChecked":!!_vm.isChecked(sub.path)},on:{"change":(e) => _vm.addLabel(e, sub)}},[_vm._v(" "+_vm._s(sub.name)+" ")])],1)}),0):_vm._e()]:_vm._e()],2)}),0),_c('a-icon',{attrs:{"type":"setting"}})],2)],1),(_vm.labelList.length > 0)?_c('div',{staticClass:"card label"},_vm._l((_vm.labelList),function(item,index){return _c('a-tag',{key:index,on:{"click":function($event){return _vm.getMenu({ path: item.labelUrl })}}},[_vm._v(" "+_vm._s(item.labelName)+" ")])}),1):_vm._e()]),_c('div',{staticClass:"task card"},[_c('div',{staticClass:"title"},[_vm._v("待办事项")]),_c('div',{staticClass:"single"},[_c('a-table',{ref:"taskTable",attrs:{"rowKey":"sampleNumber","columns":_vm.columns,"dataSource":_vm.list,"bordered":"","pagination":{
            defaultCurrent: _vm.queryParams.pageNum, // 默认当前页数
            defaultPageSize: _vm.queryParams.pageSize, // 默认当前页显示数据的大小
            total: _vm.total, // 总数，必须先有
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['20', '50', '100'],
            showTotal: (total, range) =>
              `每页${_vm.queryParams.pageSize}条，共 ${total} 条`, // 显示总数
            onShowSizeChange: _vm.onPageSizeChange,
            // 改变每页数量时更新显示
            onChange: _vm.onPageSizeChange,
          }},scopedSlots:_vm._u([{key:"action",fn:function(record){return [_c('a',{staticStyle:{"color":"#4f60ba"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.toTask(record)}}},[_vm._v("处理")])]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }