<template>
  <div class="workbench">
    <div class="main">
      <!-- 快捷方式 -->
      <div class="cont card">
        <div class="title">快捷方式</div>
        <div class="kuaijieIcon">
          <a-tooltip
            placement="bottom"
            trigger="click"
            overlayClassName="setting-workbench"
          >
            <template slot="title">
              <div
                class="single"
                v-for="(item, index) in sideBarRoutes"
                :key="index"
              >
                <template v-if="item.path !== '/home'">
                  <div class="menu-title">{{ item.name }}</div>
                  <div class="sub-list" v-if="!!item.children">
                    <div
                      class="sub-title"
                      v-for="sub in item.children"
                      :key="sub.path"
                    >
                      <a-checkbox
                        :defaultChecked="!!isChecked(sub.path)"
                        @change="(e) => addLabel(e, sub)"
                      >
                        {{ sub.name }}
                      </a-checkbox>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <a-icon type="setting" />
          </a-tooltip>
        </div>

        <div class="card label" v-if="labelList.length > 0">
          <a-tag
            v-for="(item, index) in labelList"
            :key="index"
            @click="getMenu({ path: item.labelUrl })"
          >
            {{ item.labelName }}
          </a-tag>
        </div>
        <!-- <div class="single" v-for="(item, index) in sideBarRoutes" :key="index">
            <template v-if="item.path !== '/home'">
              <div class="menu-title">{{ item.name }}</div>
              <div class="sub-list" v-if="!!item.children">
                <div
                  class="sub-title"
                  v-for="sub in item.children"
                  :key="sub.path"
                  @click="getMenu(sub)"
                >
                  <svg-icon :icon-class="sub.meta.icon" />
                  <span class="text">{{ sub.name }}</span>
                </div>
              </div>
            </template>
          </div>-->
      </div>

      <!-- 代办事项 -->
      <div class="task card">
        <div class="title">待办事项</div>
        <div class="single">
          <!-- <div class="menu-title" style="color: #000000;">我的任务</div> -->
          <a-table
            rowKey="sampleNumber"
            :columns="columns"
            :dataSource="list"
            ref="taskTable"
            bordered
            :pagination="{
              defaultCurrent: queryParams.pageNum, // 默认当前页数
              defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
              total: total, // 总数，必须先有
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ['20', '50', '100'],
              showTotal: (total, range) =>
                `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
              onShowSizeChange: onPageSizeChange,
              // 改变每页数量时更新显示
              onChange: onPageSizeChange,
            }"
          >
            <template v-slot:action="record">
              <a
                href="javascript:;"
                @click="toTask(record)"
                style="color: #4f60ba"
                >处理</a
              >
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "../utils/auth";
import { getUserLabelList, addUserLabel } from "../api/login";
import { constantRoutes } from "../router/index";
import { handleTaskListApi } from "../api/task/index.js";

export default {
  name: "Workbench",
  components: {},
  data() {
    return {
      systemInfo: {},
      isAdmin: true,
      labelList: [],
      sideBarRoutes: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: "序号 ",
          dataIndex: "index",
          width: 40,
          align: "center",
        },
        {
          title: "样品编号",
          dataIndex: "sampleNumber",
          width: 80,
        },
        {
          title: "送检人",
          dataIndex: "inspectionPerson",
          width: 70,
        },
        {
          title: "送检人电话",
          dataIndex: "inspectionPersonPhone",
          width: 100,
        },
        {
          title: "送检人时间",
          dataIndex: "inspectionPersonTime",
          width: 100,
        },
        {
          title: "样品名称",
          dataIndex: "sampleName",
          width: 80,
        },
        {
          title: "样品类型",
          dataIndex: "sampleType",
          width: 80,
        },
        {
          title: "分析项目",
          dataIndex: "analyzeProject",
          width: 80,
        },
        {
          title: "备注",
          dataIndex: "remark",
          width: 60,
        },
        {
          title: "操作",
          key: "operation",
          width: "60px",
          className: "operation-cls",
          scopedSlots: { customRender: "action" },
        },
      ],
      list: [],
    };
  },
  watch: {},
  computed: {},
  created() {
    const systemInfo = JSON.parse(getUserInfo()) || {};
    this.systemInfo = { ...systemInfo };
    this.isAdmin = systemInfo.isAdmin;
    this.labelList = systemInfo.userLabelList;
    this.getSideBarRoutes();
    this.getLabelList();
    this.handleTaskList();
  },
  methods: {
    getSideBarRoutes() {
      this.sideBarRoutes = this.filterMenuData(
        constantRoutes,
        this.systemInfo.userInfo || []
      );
      console.log(this.sideBarRoutes, "`````");
    },
    // 过滤没有权限的菜单
    filterMenuData(routes, existRoutes) {
      let newRoutes = [];
      routes.map((item) => {
        if (item.hasPermi === false) {
          newRoutes.push(item);
        } else {
          const findRoute = this.findRouteByKey(existRoutes, item.path);
          if (findRoute && !item.hidden) {
            item.meta.title = findRoute?.name || item.meta.title;
            newRoutes.push(item);
          }
        }
      });
      return newRoutes.map((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.filterMenuData(item.children, existRoutes);
        }
        return item;
      });
    },
    // 查找指定路由
    findRouteByKey(tree, key) {
      let isGet = false;
      let retNode = null;

      function deepSearch(tree, key) {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].children && tree[i].children.length > 0) {
            deepSearch(tree[i].children, key);
          }

          if (key === tree[i].functionUrl || isGet) {
            isGet || (retNode = tree[i]);
            isGet = true;
            break;
          }
        }
      }
      deepSearch(tree, key);
      return retNode;
    },
    isChecked(path) {
      return this.labelList.find((ele) => ele.labelUrl === path);
    },
    getMenu(item) {
      this.$router.push({ path: item.path }).catch(() => {});
    },
    toTask(item) {
      let params = "";
      for (let key in item) {
        if(item[key] !== null && item[key] !== '') {
          params += key + "=" + item[key] + "&";
        }
      }
      this.$router
        .push({ path: "/database/database?" + params })
        .catch(() => {});
    },
    // 获取快捷方式数据
    getLabelList() {
      getUserLabelList({
        userName: this.systemInfo.userName,
      }).then((res) => {
        if (res.success && res.data) {
          this.labelList = res.data;
        }
      });
    },
    onPageSizeChange(current, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNum: current,
        pageSize,
      };
      this.handleTaskList();
    },
    // 获取待办事项
    handleTaskList() {
      handleTaskListApi({
        ...this.queryParams
      }).then((res) => {
        if (res.success && res.rows) {
          this.list = (res.rows || []).map((ele, index) => {
            return {
              ...ele,
              index: index + 1,
            };
          });
          this.total = res.total;
        }
      });
    },
    // 增加快捷方式
    addLabel(e, item) {
      const labelList = this.labelList.map((ele) => {
        return {
          labelName: ele.labelName,
          labelUrl: ele.labelUrl,
        };
      });
      if (e.target.checked) {
        labelList.push({
          labelName: item.name,
          labelUrl: item.path,
        });
      } else {
        const index = labelList.findIndex((ele) => ele.labelUrl === item.path);
        labelList.splice(index, 1);
      }
      addUserLabel({
        username: this.systemInfo.userName,
        userLabelList: labelList,
      }).then((res) => {
        if (res.success) {
          this.getLabelList();
        }
      });
    },
  },
};
</script>

<style  scoped lang="less">
.workbench {
  background: #ffffff;
  height: 100%;

  .main {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      height: 33px;
      line-height: 33px;
      background: rgba(230, 230, 230, 0.4);
      padding-left: 9px;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      color: #3c4a54;
    }

    .cont {
      width: 30%;
      margin-right: 0px;
      overflow: auto;
      position: relative;

      .kuaijieIcon {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 6px;
      }

      .single {
        margin-bottom: 40px;

        .menu-title {
          font-size: 18px;
          font-family: MicrosoftYaHeiSemibold;
          color: #0e81ff;
          line-height: 24px;
        }

        .sub-list {
          display: flex;
          margin-top: 18px;
          height: 69px;

          .sub-title {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 19px;
            display: flex;
            align-items: center;
            margin-right: 40px;
            cursor: pointer;

            svg {
              width: 32px;
              height: 32px;
              margin-right: 5px;
            }
          }
        }
      }

      .label {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 10px 4px;
        justify-content: space-between;

        .ant-tag {
          width: 49%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          background: #5a65a3;
          color: #fff;
          margin: 0 !important;
          margin-bottom: 6px !important;
        }
      }
    }

    .task {
      width: 70%;
      .single {
        margin-bottom: 40px;
        padding: 10px;

        .menu-title {
          font-size: 16px;
          font-family: MicrosoftYaHeiSemibold;
          color: #0e81ff;
          line-height: 24px;
        }

        ::v-deep {
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            padding: 8px 16px;
          }
        }
      }
    }
  }
}
</style>
